import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import React, { useRef, useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

import * as topoData from '@/assets/topo/states-10m.json';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { displayPdlStatus, PdlStatus } from '@/features/coverage/types/pdl-status';
import { DrugCoverage } from '@/features/drugs/types/drug-coverage';

const styles = {
  state: {
    colors: {
      preferred: 'fill-secondary-600 hover:fill-secondary-600/50',
      nonPreferred: 'fill-salmon-400 hover:fill-salmon-300/50',
      other: 'fill-gray-300 hover:fill-gray-300/50',
      none: 'fill-white hover:fill-gray-100'
    }
  },
  statusIndicator: {
    colors: {
      preferred: 'bg-secondary-600',
      nonPreferred: 'bg-salmon-400',
      other: 'bg-gray-300',
      none: 'bg-white'
    }
  }
};

/**
 * Retrieves the appropriate color for the PDL status based on the given statuses and colors.
 */
const getPdlStatusColor = (
  statuses: Array<PdlStatus | undefined>,
  colors: typeof styles.statusIndicator.colors | typeof styles.state.colors
) => {
  if (isNil(statuses) || isEmpty(statuses)) return colors.none;

  const preferredStatuses = [PdlStatus.PREFERRED, PdlStatus.PREFERRED_NOT_LISTED];
  const hasPreferredStatuses = statuses.some(status => preferredStatuses.includes(status as any));

  if (hasPreferredStatuses) return colors.preferred;

  const nonPreferredStatus = [PdlStatus.NON_PREFERRED, PdlStatus.NON_PREFERRED_NOT_LISTED];
  const hasNonPreferredStatus = statuses.some(status => nonPreferredStatus.includes(status as any));

  if (hasNonPreferredStatus) return colors.nonPreferred;

  return colors.other;
};

type Tooltip = {
  data: { stateName: string; statuses: (PdlStatus | undefined)[]; },
  position: { x: number; y: number; },
}

/**
 * Tooltip component for displaying additional state information.
 */
const StateTooltip = React.forwardRef<HTMLDivElement, { tooltip: Tooltip }>(
  ({ tooltip }, ref) => {
    return (
      <div
        ref={ref}
        className={
          clsx('fixed z-[9999] rounded-md bg-white px-4 py-2 shadow-md')
        }
        style={{
          top: tooltip.position.y,
          left: tooltip.position.x,
        }}
      >
        <p className="font-semibold">{tooltip.data?.stateName}</p>
        <div className="text-sm text-gray-500">
            {isEmpty(tooltip.data.statuses) || tooltip.data.statuses.every(it => isEmpty(it)) ?
              <>No coverage details.</> : (
                tooltip.data.statuses.map((status, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <div
                      className={clsx(
                        'size-3 rounded-full',
                        getPdlStatusColor([status], styles.statusIndicator.colors)
                      )}
                    />
                    {displayPdlStatus(status!)}
                  </div>
                ))
              )
            }
          </div>
      </div>
    );
  });

/**
 * Individual item in a chart key.
 */
const ChartKeyItem = ({ keyColor, children }: { keyColor: string, children: React.ReactNode }) => {
  return (
    <div className="flex items-center gap-2">
      <div className={clsx('size-4 rounded-sm border border-zinc-700', keyColor)} />
      {children}
    </div>
  );
};

/**
 * Chart key that displays the color-coded items.
 */
const ChartKey = () => {
  return (
    <ul className="mt-8 flex gap-2">
      <li>
        <ChartKeyItem keyColor={styles.statusIndicator.colors.preferred}>
          Preferred/Preferred (Not Listed)
        </ChartKeyItem>
      </li>
      <li>
        <ChartKeyItem keyColor={styles.statusIndicator.colors.nonPreferred}>
          Non-Preferred/Non-Preferred (Not Listed)
        </ChartKeyItem>
      </li>
      <li>
        <ChartKeyItem keyColor={styles.statusIndicator.colors.other}>
          Other
        </ChartKeyItem>
      </li>
    </ul>
  );
};

/**
 * Represents a map chart showing the coverage PDL statuses across each state.
 */
export const PdlStateCoverageChart = ({
  coverages,
  isLoading
}: { coverages?: DrugCoverage[]; isLoading: boolean }) => {
  const [tooltip, setTooltip] = useState<Tooltip | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(document.createElement('div'));

  /**
   * Triggered on initial entry of the mouse over the state, setting the tooltip data and position.
   */
  const handleMouseEnter = ({
    clientX,
    clientY
  }: React.MouseEvent<SVGGElement>, stateName: string, statuses: (PdlStatus | undefined)[]) => {
    setTooltip({
      data: { stateName, statuses },
      position: { x: clientX, y: clientY },
    });
  };

  /**
   * Triggered when the mouse moves over the state, updates the tooltip position.
   */
  const handleMouseMove = (event: React.MouseEvent<SVGGElement>) => {
    const { clientX, clientY } = event;
    const offset = 12;

    const tooltipWidth = tooltipRef.current?.offsetWidth || 0;
    const tooltipHeight = tooltipRef.current?.offsetHeight || 0;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let tooltipX = clientX + offset;
    let tooltipY = clientY + offset;

    if (tooltipX + tooltipWidth > viewportWidth) {
      tooltipX = clientX - tooltipWidth - offset;
    }

    if (tooltipY + tooltipHeight > viewportHeight) {
      tooltipY = viewportHeight - tooltipHeight - offset;
    }

    setTooltip((prevState) => ({
      ...prevState,
      position: { x: tooltipX, y: tooltipY }
    }) as Tooltip);
  };

  /**
   * Triggered when the mouse exits the state, removes the current tooltip information.
   */
  const handleMouseLeave = () => {
    setTooltip(null);
  };

  return (
    <div>
      <ChartKey />
      <div className="relative">
        {isEmpty(coverages) && (
          <Overlay>
            {isLoading && <Loader />}
            {!isLoading && <p>No state coverage</p>}
          </Overlay>
        )}
        <ComposableMap projection="geoAlbersUsa">
          <Geographies geography={topoData}>
            {({ geographies }) =>
              geographies.map(geo => {
                const stateName = geo.properties.name;
                const drugCoverages = coverages
                  ?.filter(coverage => coverage.state.name === stateName) ?? [];

                const statuses = drugCoverages.map(coverage => coverage.pdlStatus);

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={(e) => handleMouseEnter(e, stateName, statuses)}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none' },
                      pressed: { outline: 'none' },
                    }}
                    className={clsx(
                      'stroke-zinc-700 transition-all',
                      getPdlStatusColor(statuses, styles.state.colors)
                    )}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      </div>
      {tooltip && <StateTooltip tooltip={tooltip} ref={tooltipRef}/>}
    </div>
  );
};
