import { DrugRef } from '@/features/drugs/types/drug';
import { Attachment } from '@/types/attachment';
import { Lookup } from '@/types/lookup';
import { OverridableCalculatedValue } from '@/types/overridable-calculated-value';
import { OverridableCompendiumValue } from '@/types/overridable-compendium-value';
import { ProprietaryCalculatedValue } from '@/types/proprietary-calculated-value';

import { ChangeLog } from '../components/show/change-log';

export type MarketBasket = {
  id: number;
  name: string;
  client: Lookup;
  currentCpiu: number;
  currentCpiuDate: string;
  lastRefreshedAt: string;
  attachments?: Attachment[];
  drugPackagings?: MarketBasketDrugPackaging[];
  changelogs?: ChangeLog[];
};

export type MarketBasketDrugPackaging = {
  ndc: string;
  name: string;
  needsAttention: boolean;
  clientId?: number;
  drug?: DrugRef;
  brandGenericStatus?: 'brand' | 'generic';
  licenseType?: string;
  currentWacUnitPrice?: OverridableCompendiumValue;
  currentWacPrice?: OverridableCompendiumValue;
  estimatedUnitRebateAmount?: number;
  acaFederalUpperLimitUnitPrice?: number;
  acaWeightedAverageManufacturersPrice?: number;
  belongsToMarketBasketDrug: boolean;
  amp: OverridableCalculatedValue;
  totalUra: OverridableCalculatedValue;
  uraPercentWac: ProprietaryCalculatedValue;
  uroa: OverridableCalculatedValue;
};

export type CalculationDetails = {
  value?: number;
  log: string;
};

/**
 * Returns the drug packagings belonging to the market basket.
 * @param items
 */
export const getDrugPackagesBelongingToMarketBasket = (items: MarketBasketDrugPackaging[]) => {
  return items.filter((item) => item.belongsToMarketBasketDrug);
};

/**
 * Returns the drug packagings that need attention.
 * @param items
 */
export const getDrugPackagesNeedingAttention = (items: MarketBasketDrugPackaging[]) => {
  return items.filter((item) => item.needsAttention);
};
