import React from 'react';

import { DrugList } from '@/features/home/drug-list';
import { CurrentUser } from '@/types/me';

type ClientUserHomeProps = {
  user: CurrentUser;
};

export const ClientUserHome = ({ user }: ClientUserHomeProps) => {
  return (
    <div>
      {user.premiumFeatures.includes('drug_info') ? (
        <DrugList/>
      ) : (
        <>Your account has not been set up completely. Please contact your administrator for assistance.</>
      )}
    </div>
  );
};
