import { ProtectedRoute } from '@shared/auth';
import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper, getCoreRowModel, getSortedRowModel, SortingState } from '@tanstack/react-table';
import { isEmpty, isNil, parseInt } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LiaFolderOpen } from 'react-icons/lia';

import Nullish from '@/components/nullish';
import { Button } from '@/components-new/button';
import { DataTable } from '@/components-new/data-table';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { useDrug } from '@/features/drugs/api/use-drug';
import { makeDrugCoveragesQueryKey, useDrugCoverages } from '@/features/drugs/api/use-drug-coverages';
import { CoverageDetailsDrawer } from '@/features/drugs/components/coverage-details-drawer';
import CoverageTagsCell from '@/features/drugs/components/coverage-tags-cell';
import { PdlStateCoverageChart } from '@/features/drugs/components/pdl-state-coverage-chart';
import { PdlStatusBadge } from '@/features/drugs/components/pdl-status-badge';
import { DrugCoverage } from '@/features/drugs/types/drug-coverage';
import { dismissNotification, notifyError } from '@/lib/notification/notifications';
import { useTable } from '@/lib/react-table';
import { useParams } from '@/router';
import { drugName } from '@/utils/drugs';
import { stepTherapyDisplay } from '@/utils/state-coverage';

const columnHelper = createColumnHelper<DrugCoverage>();

const PaTypesCell = ({ coverage }: { coverage: DrugCoverage }) => {
  const {
    paTypes
  } = coverage;

  if (!paTypes || paTypes.length === 0) return <Nullish />;

  return paTypes.map(paType => paType.label).join('; ');
};

const DrugCoveragePage = () => {
  const { id } = useParams('/drugs/:id');
  const { data: drug } = useDrug(id);
  const {
    data: coverages,
    isLoading: isLoadingStateCoverages,
    isError: isErrorStateCoverages,
  } = useDrugCoverages({ drugId: parseInt(id) });
  const [coverageDetailsForReview, setCoverageDetailsForReview] = useState<DrugCoverage | undefined>();
  const queryClient = useQueryClient();

  const handleRetry = useCallback(async () => {
    await queryClient.resetQueries({ queryKey: makeDrugCoveragesQueryKey(parseInt(id)) });
  }, [id, queryClient]);

  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'state', desc: false }]);

  // display error notification with support to retry on error
  useEffect(() => {
    if (isErrorStateCoverages) {
      const id = notifyError({
        message: 'We were unable to load coverage information at this time.',
        actions: (
          <Button
            plain
            color="secondary"
            onClick={() => {
              void handleRetry();
              dismissNotification(id);
            }}
          >
            Retry
          </Button>
        )
      });
    }
  }, [handleRetry, isErrorStateCoverages]);

  const columns = useMemo(() => [
    columnHelper.accessor(
      (row) => row.state.name,
      {
        id: 'state',
        header: 'State',
      }
    ),
    columnHelper.accessor('coverageTags', {
      header: '',
      cell: info => <CoverageTagsCell coverageTags={info.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor('pdlStatus', {
      header: 'PDL Status',
      cell: info => <PdlStatusBadge pdlStatus={info.getValue()}/>,
      enableSorting: false
    }),
    columnHelper.accessor('paTypes', {
      header: 'PA Type',
      cell: ({ row }) => <PaTypesCell coverage={row.original} />,
      enableSorting: false
    }),
    columnHelper.accessor('stepTherapyCount', {
      header: 'Step Therapy',
      cell: ({ row }) => (
        <Nullish
          value={stepTherapyDisplay(row.original.stepTherapyCount, row.original.hasAdditionalSteps)}
        />
      ),
      enableSorting: false
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <span className="sr-only">Actions</span>
      ),
      cell: ({ row }) => (
        <div className="flex justify-end">
          <Button
            outline
            onClick={() => setCoverageDetailsForReview(row.original)}>
            Details
          </Button>
        </div>
      ),
      enableSorting: false
    })
  ], []);

  const table = useTable({
    data: coverages ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    onSortingChange: setSorting,
    state: {
      sorting
    }
  });

  const showEmptyTableEmpty = !isLoadingStateCoverages && (isEmpty(coverages) || isNil(coverages));

  return (
    <div className="mt-4">
      <div className="grid gap-16 md:grid-cols-2">
        <div className="flex flex-col">
          <div>
            <SectionHeading level={2}>
              PDL Status Overview
            </SectionHeading>
            <SectionDescription>
              PDL Status of the drug within each state.
            </SectionDescription>
          </div>
          <PdlStateCoverageChart coverages={coverages} isLoading={isLoadingStateCoverages} />
        </div>
      </div>
      <div className="mt-4">
        <div className="sm:flex-auto">
          <SectionHeading level={2}>Coverage</SectionHeading>
          <SectionDescription>
            How each state covers {drugName(drug)} and their prior authorization requirements.
          </SectionDescription>
        </div>
        <div className="relative mt-8 flow-root">

          <DataTable
            table={table}
            placeholder={() => (
              <div className="relative flex h-48 items-center justify-center">
                {isLoadingStateCoverages && (
                  <Overlay>
                    <Loader message="Loading coverage..." />
                  </Overlay>
                )}
                {showEmptyTableEmpty && (
                  <EmptyState>
                    <LiaFolderOpen className="size-12 text-gray-500"/>
                    <EmptyStateHeading>No coverage</EmptyStateHeading>
                    <EmptyStateBody>
                      We're working to bring you the latest coverage information.
                    </EmptyStateBody>
                  </EmptyState>
                )}
              </div>
            )}
          />
        </div>
        <CoverageDetailsDrawer
          coverage={coverageDetailsForReview}
          onClose={() => setCoverageDetailsForReview(undefined)}
        />
      </div>
    </div>
  );
};

const DrugCoveragePageContainer = () => {
  return (
    <ProtectedRoute>
      <DrugCoveragePage/>
    </ProtectedRoute>
  );
};

export default DrugCoveragePageContainer;
