import clsx from 'clsx';
import React from 'react';

import { DrugDisplayable } from '@/features/drugs/types/drug';

type DrugNameProps = {
  drug?: DrugDisplayable;
  includeGenericName?: boolean;
};

/**
 * Formats a drug name for display.
 *
 * @param drug The drug to format the name for.
 * @param includeGenericName Whether to include the generic name in the
 * formatted name.
 */
export const DrugName = ({ drug, includeGenericName = false }: DrugNameProps) => {
  if (!drug) {
    return null;
  }

  return (
    <>
      <span className={clsx(drug.displayAsBrand && 'uppercase')}>{drug.name}</span>
      {includeGenericName && drug.genericName && (
        <span>({drug.genericName})</span>
      )}
    </>
  );
};
