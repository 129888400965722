import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCalendarQuery } from '@/features/calendar/api';
import { Calendar } from '@/features/calendar/components/calendar';
import { MeetingDetailsDrawer, MeetingsList } from '@/features/calendar/components/meetings';
import { Meeting } from '@/features/calendar/types/meeting';
import { useDebounce } from '@/hooks/use-debounce';

const getInitialMonth = (month: string | null): DateTime => {
  if (month === null) {
    return DateTime.now();
  }

  return DateTime.fromISO(month);
};

const MonthMinMax = {
  min: DateTime.now().minus({ month: 6 }).toFormat('yyyy-MM'),
  max: DateTime.now().plus({ month: 6 }).toFormat('yyyy-MM'),
};

type CalendarMode = 'all' | 'tracked' | 'priority';

type CalendarPageContentsProps = {
  mode: CalendarMode;
  hint: string;
  emptyState?: React.ReactNode;
}

export const CalendarPageContents = ({ mode, hint, emptyState }: CalendarPageContentsProps) => {
  const [params, setParams] = useSearchParams();
  const initialMonth = getInitialMonth(params.get('month'));

  const [month, setMonth] = useState<DateTime>(initialMonth);

  // debounce the search of meetings to avoid spamming if the user navigates quickly
  const monthFilter = useDebounce(month);

  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | undefined>();

  const {
    data: meetings,
    isLoading: isMeetingsLoading,
  } = useCalendarQuery({
    filters: {
      month: monthFilter.toFormat('yyyy-MM'),
      tracked: mode === 'tracked' || mode === 'priority',
      priority: mode === 'priority'
    }
  });

  const daysWithEvents = useMemo(() => {
    return meetings?.map(meeting => {
      const startTime = DateTime.fromISO(meeting.startTime);
      startTime.setZone(meeting.timezone);
      return startTime.toFormat('yyyy-MM-dd');
    }) ?? [];
  }, [meetings]);

  const showMeetingDetails = (meeting: Meeting) => {
    setSelectedMeeting(meeting);
  };

  const onCalendarNavigate = (newMonth: DateTime) => {
    setSelectedMeeting(undefined);
    setParams(`month=${newMonth.toFormat('yyyy-MM')}`);
    setMonth(newMonth);
  };

  return (
    <div className="mt-4">
      <h2 className="text-lg font-semibold leading-6 text-zinc-950 dark:text-white">{`${monthFilter.monthLong} Meetings`}</h2>
      <p className="mt-1 text-sm text-gray-700">{hint}</p>
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
        <Calendar
          className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9"
          current={month}
          min={MonthMinMax.min}
          max={MonthMinMax.max}
          daysWithEvents={daysWithEvents}
          onChange={onCalendarNavigate}
        />

        <div className="relative mt-4 min-h-20 lg:col-span-7 xl:col-span-8">
          <MeetingsList
            isLoading={isMeetingsLoading}
            meetings={meetings}
            emptyState={emptyState}
            onShowMeetingDetails={showMeetingDetails}
          />
        </div>
      </div>

      <MeetingDetailsDrawer
        meeting={selectedMeeting}
        onClose={() => setSelectedMeeting(undefined)}
      />
    </div>
  );
};
