export type ColumnConfigurationGroup = string;

export enum ColumnConfigurationType {
  STRING = 'string',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  DATE_ONLY = 'date_only',
  URL = 'url',
  MULTILINE_STRING = 'multiline_string'
}

export enum ColumnConfigurationUsage {
  UPDATE = 'update',
  VIEW_FROM_TABLE = 'view_from_table'
}

export type ColumnConfiguration = {
  header: string;
  description: string;
  propertyName: string;
  type: ColumnConfigurationType;
  usages: ColumnConfigurationUsage[];
  possibleValues?: string[];
};

export type CustomState = {
  name: string;
  code: string;
  values: Record<string, string>;
};
