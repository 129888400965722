import { useMarkdown } from '@shared/markdown';
import React from 'react';

import { Button } from '@/components-new/button';
import { Dialog, DialogActions,DialogBody, DialogDescription, DialogTitle } from '@/components-new/dialog';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { RichTextPreview } from '@/components-new/rich-text-editor';
import { useNewsItem } from '@/features/news/api';
import { PostMeta } from '@/features/news/components/news-feed';
import { useNavigate,useParams } from '@/router';

export const NewsArticle = () => {
  const { id } = useParams('/news/:id');

  const { data: post, isLoading, isSuccess, isError } = useNewsItem(parseInt(id));

  const { toHtml } = useMarkdown();

  const navigate = useNavigate();
  return (
    <Dialog
      onClose={() => navigate('/news')}
      open={true} size="2xl"
      className="relative"
    >
        <DialogTitle>
          {isSuccess && post.title}
          {isError && <>Something went wrong</>}
        </DialogTitle>
        <DialogDescription>
          {isSuccess && <PostMeta post={post} />}
          {isError && <>There was an issue will loading this post.</>}
        </DialogDescription>
        <DialogBody>
          {isLoading && (
            <div className="relative h-40">
              <Overlay>
                <Loader message="Loading article..."/>
              </Overlay>
            </div>
          )}
          {isSuccess && (
            <RichTextPreview content={toHtml(post.content)} className="max-w-3xl"/>
          )}
          {
            isError && (
              <p>
                Please try again later.
              </p>
            )
          }
      </DialogBody>
      <DialogActions>
        <Button color="secondary" href="/news">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewsArticle;
