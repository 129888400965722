import { CoverageTag } from '@/features/drugs/types/coverage-tag';

type Props = {
  coverageTags: CoverageTag[];
};

type CoverageTagSortOrder = {
  coverageTag: CoverageTag;
  sortOrder: number;
};

const CoverageTagsCell = ({ coverageTags }: Props) => {
  const coverageTagsDisplay = coverageTags.map(tag => {
    switch (tag.type?.code) {
      case 'F':
        return { coverageTag: tag, sortOrder: 1 };
      case 'D':
        return { coverageTag: tag, sortOrder: 2 };
      case 'O':
        return { coverageTag: tag, sortOrder: 3 };
      case 'I':
        return { coverageTag: tag, sortOrder: 4 };
      default:
        return { coverageTag: tag, sortOrder: 5 };
    }
  });

  return (
    coverageTagsDisplay
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(item => item.coverageTag.name)
      .join(', ')
  );
};

export default CoverageTagsCell;
