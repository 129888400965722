import { useMarkdown } from '@shared/markdown';
import { getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { LiaNewspaper } from 'react-icons/lia';

import { Timestamp } from '@/components/date-time';
import { Badge } from '@/components-new/badge';
import { DataPaginator } from '@/components-new/data-paginator';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { RichTextPreview } from '@/components-new/rich-text-editor';
import { useNews } from '@/features/news/api';
import { PostBase } from '@/features/posts/types/post-base';
import { useTable } from '@/lib/react-table';
import { Link } from '@/router';

export const NewsFeed = () => {
  const { data: newsItems, isLoading } = useNews();
  const showLoader = isLoading;
  const hasNewsItems = !isNil(newsItems) && !isEmpty(newsItems);
  const showNewsFeed = hasNewsItems && !showLoader;
  const showEmptyState = !isLoading && !hasNewsItems;

  const feed = useTable({
    data: newsItems || [],
    columns: [],
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      {showLoader && (
        <div className="relative h-20">
          <Overlay>
            <Loader message="Loading news..."/>
          </Overlay>
        </div>
      )}
      {
        showEmptyState && (
          <EmptyState>
            <LiaNewspaper className="size-12 text-gray-500"/>
            <EmptyStateHeading>No news</EmptyStateHeading>
            <EmptyStateBody>
              Check back soon and stay tuned for the latest news.
            </EmptyStateBody>
          </EmptyState>
        )
      }
      {showNewsFeed && (
        <div className="space-y-8">
          {feed.getRowModel().rows.map((row) => <NewsFeedItem key={row.id} post={row.original} />)}
        </div>
      )}
      <div className="mt-4">
        <DataPaginator table={feed}/>
      </div>
    </div>
  );
};

const NewsFeedItem = ({ post }: { post: PostBase }) => {
  const { toHtml } = useMarkdown();

  return (
    <article className="flex max-w-xl flex-col items-start justify-between border-b">
      <div className="group relative">
        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
          <Link to="/news/:id" params={{ id: post.id.toString() }}>
            <span className="absolute inset-0"/>
            {post.title}
          </Link>
        </h3>
        <PostMeta post={post} />
        <div className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
          <RichTextPreview content={toHtml(post.summary)} />
        </div>
      </div>
    </article>
  );
};

export const PostMeta = ({ post }: { post: PostBase }) => {
  return (
    <div className="flex items-center gap-x-4 text-xs">
      <time dateTime={post.postedAt} className="text-gray-500">
        <Timestamp value={post.postedAt}/>
      </time>
      <Badge>
        {post.category.label}
      </Badge>
    </div>
  );
};
