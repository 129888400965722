import { DateTime } from 'luxon';
import { z } from 'zod';

import {
  codeBasedLookupSchema,
  idBasedLookupSchema,
  slugBasedLookupSchema
} from '@/types/lookup';

export const meetingFormatSchema = ['in_person', 'virtual', 'hybrid'] as const;

export type MeetingFormat = typeof meetingFormatSchema[number];

export const meetingLocationAddressSchema = z.object({
  line1: z.string(),
  line2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  zipcode: z.string(),
});

export const meetingWebinarSchema = z.object({
  url: z.string().nullish(),
  phone: z.string().nullish(),
  meetingId: z.string().nullish(),
  password: z.string().nullish(),
});

export const meetingLocationSchema = z.object({
  venue: z.string().nullish(),
  room: z.string().nullish(),
  address: meetingLocationAddressSchema.nullish(),
});

const meetingResourcesSchema = z.object({
  agendaLink: z.string().nullish(),
  meetingMinutesLink: z.string().nullish(),
  recordingLink: z.string().nullish(),
  speakerPoliciesLink: z.string().nullish(),
});

export const meetingSchema = z.object({
  id: z.number(),
  state: codeBasedLookupSchema,
  startTime: z.string(),
  endTime: z.string(),
  timezone: z.string(),
  format: z.enum(meetingFormatSchema),
  type: codeBasedLookupSchema,
  drugs: slugBasedLookupSchema.array(),
  classifications: idBasedLookupSchema.array(),
  boardMembers: z.string().nullish(),
  location: meetingLocationSchema.nullish(),
  webinar: meetingWebinarSchema.nullish(),
  resources: meetingResourcesSchema.nullish()
});

export type Meeting = z.infer<typeof meetingSchema>;

export const formatMeetingFormat = (format: MeetingFormat) => {
  switch (format) {
    case 'in_person':
      return 'In-Person';
    case 'virtual':
      return 'Virtual';
    case 'hybrid':
      return 'Hybrid';
  }
};

export const parseMeetingDateTime = (meeting: Meeting) => {
  const startTime = DateTime.fromISO(meeting.startTime);
  const endTime = DateTime.fromISO(meeting.endTime);

  const localStartTime = startTime.setZone(meeting.timezone);
  const localEndTime = endTime.setZone(meeting.timezone);

  return {
    date: localStartTime.toFormat('DDD'),
    startTime: localStartTime.toFormat('h:mm a ZZZZ'),
    endTime: localEndTime.toFormat('h:mm a ZZZZ')
  };
};
