export enum PdlStatus {
  BLANK = 'blank',
  PREFERRED = 'preferred',
  NON_PREFERRED = 'non_preferred',
  NON_PDL = 'non_pdl',
  NO_STATE_PDL = 'no_state_pdl',
  PREFERRED_NOT_LISTED = 'preferred_not_listed',
  NON_PREFERRED_NOT_LISTED = 'non_preferred_not_listed'
}

export const displayPdlStatus = (pdlStatus: PdlStatus): string | null => {
    switch (pdlStatus) {
      case PdlStatus.BLANK:
        return 'Blank';
      case PdlStatus.PREFERRED:
        return 'Preferred';
      case PdlStatus.NON_PREFERRED:
        return 'Non-Preferred';
      case PdlStatus.NON_PDL:
        return 'Non-PDL';
      case PdlStatus.NO_STATE_PDL:
        return 'No State PDL';
      case PdlStatus.PREFERRED_NOT_LISTED:
        return 'Preferred (Not Listed)';
      case PdlStatus.NON_PREFERRED_NOT_LISTED:
        return 'Non-Preferred (Not Listed)';
     default:
       return 'Blank';
    }
};

export const pdlStatusOptions = [
  PdlStatus.BLANK,
  PdlStatus.PREFERRED,
  PdlStatus.NON_PREFERRED,
  PdlStatus.NON_PDL,
  PdlStatus.NO_STATE_PDL,
  PdlStatus.PREFERRED_NOT_LISTED,
  PdlStatus.NON_PREFERRED_NOT_LISTED,
].map((pdlStatus) => ({ id: pdlStatus, label: displayPdlStatus(pdlStatus) }));
