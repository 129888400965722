import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { HiOutlineSearch } from 'react-icons/hi';
import { LiaPillsSolid, LiaSearchSolid } from 'react-icons/lia';

import Nullish from '@/components/nullish';
import { Button } from '@/components-new/button';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '@/components-new/description-list';
import { Heading } from '@/components-new/heading';
import { Input, InputGroup } from '@/components-new/input';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { Drug } from '@/features/drugs/types/drug';
import { useTrackedDrugs } from '@/features/home/api/get-tracked-drugs';
import { useDebounce } from '@/hooks/use-debounce';

export const DrugList = () => {
  const [search, setSearch] = React.useState('');
  const { data: drugs, isLoading, } = useTrackedDrugs({});

  const searchTerm = useDebounce(search, 250);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
  };

  const filteredDrugs = useMemo(
    () => drugs?.filter(it => it.name.toLowerCase().includes(searchTerm.toLowerCase())) ?? [],
    [searchTerm, drugs]
  );

  const emptyResults = !isLoading && filteredDrugs.length === 0;

  return (
    <>
      <div className="mb-3 flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
        <Heading level={5} className="font-serif">Drugs</Heading>
        {drugs && drugs.length > 0 && (
          <div className="sm:w-80">
            <InputGroup>
              <HiOutlineSearch data-slot="icon" size="1rem"/>
              <Input
                name="drugNameFilter"
                type="search"
                placeholder="Filter by Drug Name"
                aria-label="Filter by Drug Name"
                value={search}
                onChange={onSearch}
              />
            </InputGroup>
          </div>
        )}
      </div>
      <div className="relative min-h-32">
        {emptyResults && <DrugsEmptyState search={searchTerm}/>}
        <DrugsList drugs={filteredDrugs}/>
        {isLoading && (
          <Overlay>
            <Loader message="Loading drugs..."/>
          </Overlay>
        )}
      </div>
    </>
  );
};

const DrugsList = ({ drugs }: { drugs: Drug[] }) => {
  return (
    <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 xl:grid-cols-3 xl:gap-x-8">
      {drugs.map((drug) => <DrugCard drug={drug} key={drug.name}/>)}
    </ul>
  );
};

const DrugsEmptyState = ({ search }: { search: string }) => {
  const emptySearch = isEmpty(search);

  return (
    <div className="text-center">
      {emptySearch ?
        <LiaPillsSolid className="mx-auto text-gray-400" size="3rem"/> :
        <LiaSearchSolid className="mx-auto text-gray-400" size="3rem"/>
      }
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        {emptySearch ? 'No drugs' : `No results for "${search}"`}
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {
          emptySearch ?
            'Your account may not be fully provisioned. Please contact Artia Solutions.' :
            'Try adjusting your search.'
        }
      </p>
    </div>
  );
};

const DrugCard = ({ drug }: { drug: Drug }) => {
  return (
    <li
      key={drug.name}
      className="overflow-hidden rounded-lg border border-gray-300"
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <div className="text-base/5 font-bold leading-6 text-gray-900">{drug.name}</div>
        <Button className="ml-auto" outline href={`/drugs/${drug.id}/`}>
          View
        </Button>
      </div>
      <DescriptionList className="px-6">
        <DescriptionTerm>Classification</DescriptionTerm>
        <DescriptionDetails className="text-right">
          <Nullish value={drug.classification?.name}/>
        </DescriptionDetails>

        {/*TODO: implement this
        <DescriptionTerm>Upcoming Meetings</DescriptionTerm>
        <DescriptionDetails className="text-right">
          <time dateTime="">
            <Nullish
              value=""
              valueRenderer={
                (value) => (
                  <Link href="#">
                    {new Date(value).toLocaleDateString()}
                  </Link>
                )}
              nullRenderer={() => 'No upcoming meetings'}
            />
          </time>
        </DescriptionDetails>*/}
      </DescriptionList>
    </li>
  );
};
